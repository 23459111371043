import React, { lazy, Suspense } from 'react';
import { PostHogProvider } from 'posthog-js/react';

import { createRoot } from 'react-dom/client';
import pMinDelay from 'p-min-delay';
import { SupportedApps, SupportedProducts } from './constants';
import { ProductContext } from './ProductContext';
import { renderForTest } from '../../tests/testUtils';
import { isDevelopment } from './environment';

const options = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
};
export const Loadable = (factory) => () => {
  const Component = lazy(factory.loader);

  return (
    <Suspense fallback={<p>...</p>}>
      <Component />
    </Suspense>
  );
};

const LoadableExitIntentApp = Loadable({
  loader: () =>
    pMinDelay(import(/* webpackChunkName: "ExitIntentApp" */ '../ExitIntentApp/ExitIntent'), 2),
  loading: () => <p></p>,
});

const mountingElements = {
  exitIntentAppElem: document.getElementById('exitintent-app'),
};

export const getCurrentProductCode = () => {
  const appToBoot = getAppToBoot();
  if (appToBoot === SupportedApps.exitIntentApp) {
    return SupportedProducts.EMAILYARD;
  } else if (appToBoot === SupportedApps.sidleApp) {
    return SupportedProducts.SIDLE;
  } else if (appToBoot === SupportedApps.crisperAi) {
    return SupportedProducts.CRISPER;
  }
  return SupportedProducts.EMAILYARD;
};

export const isEmailyard = () => {
  const appToBoot = getAppToBoot();
  if (appToBoot === SupportedApps.exitIntentApp) {
    return true;
  }
  return false;
};

export const isCrisper = () => {
  const appToBoot = getAppToBoot();
  if (appToBoot === SupportedApps.crisperAi) {
    return true;
  }
  return false;
};

export const isSidle = () => {
  const appToBoot = getAppToBoot();
  if (appToBoot === SupportedApps.sidleApp) {
    return true;
  }
  return false;
};

export const getAppToBoot = () => {
  // Figure out app from hostname
  const hostname = window.location.hostname;
  if (hostname.match('crisper')) {
    return SupportedApps.crisperAi;
  } else if (hostname.match('sidle')) {
    return SupportedApps.sidleApp;
  } else if (hostname.match('emailyard')) {
    return SupportedApps.exitIntentApp;
  } else if (hostname === 'app.sendybay.com' || hostname.match('sendybay.netlify')) {
    return SupportedApps.exitIntentApp;
  }

  // Figure out app from localstorage
  const localBootAppVal = window.localStorage.getItem('SB_BOOT_APP');
  if (localBootAppVal !== null && Object.values(SupportedApps).includes(localBootAppVal)) {
    return localBootAppVal;
  }
  return SupportedApps.exitIntentApp;
};

export const setAppToBoot = (appName) => {
  if (!Object.values(SupportedApps).includes(appName)) {
    throw Error(`Cannot boot app ${appName}`);
  }
  window.localStorage.setItem('SB_BOOT_APP', appName);
};

export const unmountReactApp = (elem) => {
  ReactDOM.unmountComponentAtNode(elem);
};

const renderBeeApp = () => {
  mountingElements.beeAppElem = document.getElementById('bee-app');
  return createRoot(mountingElements.beeAppElem).render(<div>Not supported</div>);
};

const renderCrisperApp = () => {
  return createRoot(mountingElements.mountCrisperElem).render(<LoadableCrisperApp />);
};

const renderExitIntentApp = ({ productCode = SupportedProducts.EMAILYARD, target = null }) => {
  const productConfig = {
    [SupportedProducts.EMAILYARD]: {
      name: 'Emailyard',
      supportEmail: 'support@emailyard.com',
      squareLogoUrl: 'https://sendybay-uploads.s3.amazonaws.com/public-files/ey-old-square.png',
      squareLogoUrl2x:
        'https://sendybay-uploads.s3.amazonaws.com/public-files/ey-old-square-2x.png',
    },
    [SupportedProducts.SIDLE]: {
      name: 'Sidle',
      supportEmail: 'support@sidle.co',
      squareLogoUrl: 'https://ey-cdn.s3.amazonaws.com/sidle-logo.png',
      squareLogoUrl2x: 'https://ey-cdn.s3.amazonaws.com/sidle-logo.png',
    },
    [SupportedProducts.CRISPER]: {
      name: 'Crisper',
      supportEmail: 'support@crisper.ai',
      squareLogoUrl: 'https://ey-cdn.s3.amazonaws.com/crisper-logo-wide.png',
      squareLogoUrl2x: 'https://ey-cdn.s3.amazonaws.com/crisper-logo-wide.png',
      logoClassName: 'crisper-onboarding-logo',
      // squareLogoUrl:
      //   'https://cdn.prod.website-files.com/65ee7e8af77c707e016649f7/666429ec13e2f27c3d73090b_Product%20Screens%20Frame%206.png',
      // squareLogoUrl2x:
      //   'https://cdn.prod.website-files.com/65ee7e8af77c707e016649f7/666429ec13e2f27c3d73090b_Product%20Screens%20Frame%206.png',
    },
  };
  const toRender = (
    <ProductContext.Provider
      value={{
        productConfig: productConfig[productCode],
        productCode,
        isCrisper: productCode === SupportedProducts.CRISPER,
        isEmailyard: productConfig === SupportedProducts.EMAILYARD,
      }}
    >
      <LoadableExitIntentApp />
    </ProductContext.Provider>
  );

  if (import.meta.env.VITEST) {
    return renderForTest(toRender);
  }

  if (isDevelopment()) {
    return createRoot(target || mountingElements.exitIntentAppElem).render(
      <React.StrictMode>{toRender}</React.StrictMode>,
    );
  }
  return createRoot(target || mountingElements.exitIntentAppElem).render(
    <React.StrictMode>
      <PostHogProvider apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY} options={options}>
        {toRender}
      </PostHogProvider>
    </React.StrictMode>,
  );
};

export const mountEmailyard = ({ target = null } = {}) => {
  return renderExitIntentApp({ target });
};

export const mountSidle = () => {
  mountingElements.exitIntentAppElem = document.getElementById('exitintent-app');
  return renderExitIntentApp({ productCode: SupportedProducts.SIDLE });
};

export const mountBee = () => {
  return renderBeeApp();
};

export const mountCrisper = () => {
  mountingElements.mountCrisperElem = document.getElementById('crisper-app');
  return renderExitIntentApp({ productCode: SupportedProducts.CRISPER });
};
